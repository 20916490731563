/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

const HeroSection = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulHomepage {
      heroBanner {
        buttonName
        buttonSlug
        title
        description {
          description
        }
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    },
    contentfulEpisodesPage {
      episode {
        name
        title
        slug
        releaseDate
        duration
        description
        video {
          url
          vidoePlaceholder {
            gatsbyImageData(placeholder: BLURRED)
            url
            title
          }
        }
        featureTitle
        featuringLists {
          name
          url
        }
      }
    },
    contentfulWhatsOnPage {
      name
      whatsOnList {
        slug
        featuredName
        featuredDescription {
          featuredDescription
        }
        heroImage {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    },
    contentfulUnderdogPage {
      name
      underdogList {
        slug
        featuredName
        featuredDescription {
          featuredDescription
        }
        heroImage {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`);

  const { contentfulEpisodesPage } = data || {};
  const episodeVideosLength = contentfulEpisodesPage.episode.length - 1;
  const firstEpisodePost = contentfulEpisodesPage.episode[episodeVideosLength];

  const { contentfulWhatsOnPage } = data || {};
  const whatsonVideosLength = contentfulWhatsOnPage.whatsOnList.length - 1;
  const firstWhatsOnPost = contentfulWhatsOnPage.whatsOnList[whatsonVideosLength];

  const { contentfulUnderdogPage } = data || {};
  const videosLength = contentfulUnderdogPage.underdogList.length - 1;
  const firstUnderdogPost = contentfulUnderdogPage.underdogList[videosLength];

  const { contentfulHomepage } = data || {};
  const bannerPost = contentfulHomepage.heroBanner;

  return (
    <div className="h-full relative">
      <div className="relative flex content-center items-center mb-[1.5rem] min-h-[40vh] lg:min-h-[68vh]">
        <div className="overflow-hidden bg-indigo-500">
          <div className="absolute h-full flex top-0 w-full bg-center bg-cover bg-gradient-to-br from-black via-gray-900 to-transparent">
            <GatsbyImage style={{ height: 'auto' }} image={bannerPost.image.gatsbyImageData} alt={bannerPost.title} className="w-full h-auto object-cover" />
          </div>
        </div>
        <div className="relative md:px-[2rem] px-[1.5rem] lg:px-[2.5rem] w-full">
          <div className="flex flex-col justify-center items-center md:items-start space-y-5">
            <div className="w-full">

              <div className="md:pr-12 md:w-full lg:w-[65%]">
                <h1 className="text-heading text-2xl md:text-[32px] text-center md:text-left font-semibold lg:text-6xl mb-[0.5]">
                  {bannerPost.title}
                </h1>
                <h2 className="lg:w-[60%] w-full mt-2 md:text-[22px] text-center md:text-left text-[18px] leading-6 md:leading-6 text-heading mb-4">
                  {bannerPost.description.description}
                </h2>
              </div>
            </div>
            <div className="pb-[14px]">
              <Link
                to={bannerPost.buttonSlug}
                className="inline items-center justify-center px-[3.5rem] min-w-[10.625rem] py-[1rem] border border-transparent rounded-md shadow-sm md:text-headerP text-sm font-medium text-background bg-secondary hover:bg-heading"
              >
                {bannerPost.buttonName}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <section className="h-full bg-background inherit">
        <div className="md:px-[1.25rem] lg:px-[2rem]">
          <div className="md:-mt-24 -mt-10 pb-[3rem] lg:pb-[40px]">
            <div className="grid grid-cols-1 grid-flow-row md:grid-cols-2 md:grid-flow-row lg:grid-cols-3 md:gap-x-5 2xl:gap-2 ipad:grid-cols-3 ipad:gap-x-2 px-0 lg:px-3 gap-y-10 overflow-hidden">
              <Link to={`/episodes/${firstEpisodePost?.slug}`} className="w-[98%] md:w-full pl-0 lg:pl-[0.5rem] group duration-200 delay-75 hover:z-30 z-20">
                <div className="relative z-30 transition transform duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105 before:group-hover:bg-primary before:group-hover:left-[-3px] before:h-full before:w-[0.125rem] before:absolute before:left-[0]">
                  <div className="overflow-hidden mb-[8px]">
                    <div className="h-[200px] lg:h-[200px] 3xl:h-[440px]">
                      <GatsbyImage image={firstEpisodePost.video.vidoePlaceholder.gatsbyImageData} alt={firstEpisodePost.name} className="shadow-md w-full z-0 h-full group-hover:shadow-lg" />
                    </div>
                    <div className="flex w-[75%] flex-row content-end flex-wrap opacity-0 group-hover:opacity-90 inset-0 z-10 absolute duration-300 pl-[0.5rem] pr-[1.5rem]">
                      <span className="text-text text-lg font-medium block 3xl:ml-5">{firstEpisodePost.name}</span>
                      <p className="block mt-[0.5rem] mb-[0.75rem] font-normal text-text text-lg 3xl:ml-5">
                        {`${firstEpisodePost.description.substring(0, 50)}...`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="inherit text-text group-hover:text-primary text-[18px] transform transition duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105 mt-4 3xl:group-hover:ml-5">Episodes</div>
              </Link>
              <Link to={`/whatson/${firstWhatsOnPost.slug}`} className="w-[98%] md:w-full pl-[0.5rem] group duration-200 delay-75 hover:z-30 z-20">
                <div className="relative z-30 transition transform duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105 before:group-hover:bg-primary before:group-hover:left-[-3px] before:h-full before:w-[0.125rem] before:absolute before:left-[0]">
                  <div className="overflow-hidden mb-[8px]">
                    <div className="h-[200px] lg:h-[200px] 3xl:h-[440px]">
                      <GatsbyImage image={firstWhatsOnPost.heroImage.gatsbyImageData} alt={firstUnderdogPost.featuredName} className="shadow-md w-full z-0 h-full group-hover:shadow-lg" />
                    </div>
                    <div className="flex flex-row w-[75%] content-end flex-wrap opacity-0 group-hover:opacity-90 inset-0 z-10 absolute duration-300 pl-[0.5rem] pr-[1.5rem]">
                      <span className="text-text text-lg font-medium block 3xl:ml-5 mb-[12px]">{firstWhatsOnPost.featuredName}</span>
                    </div>
                  </div>
                </div>
                <div className="inherit text-text group-hover:text-primary text-[18px] transform transition duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105 mt-4 3xl:group-hover:ml-5">{data.contentfulWhatsOnPage.name}</div>
              </Link>
              <Link to={`/underdog/${firstUnderdogPost.slug}`} className="w-[98%] md:w-full pl-[0.5rem] group duration-200 delay-75 hover:z-30 z-20">
                <div className="relative z-30 transition transform duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105 before:group-hover:bg-primary before:group-hover:left-[-3px] before:h-full before:w-[0.125rem] before:absolute before:left-[0]">
                  <div className="overflow-hidden mb-[8px]">
                    <div className="h-[200px] lg:h-[200px] 3xl:h-[440px]">
                      <GatsbyImage image={firstUnderdogPost.heroImage.gatsbyImageData} alt={firstUnderdogPost.featuredName} className="shadow-md w-full h-full z-0 group-hover:shadow-lg" />
                    </div>
                    <div className="flex flex-row w-[75%] content-end flex-wrap opacity-0 group-hover:opacity-90 inset-0 z-10 absolute duration-300 pl-[0.5rem] pr-[1.5rem]">
                      <span className="text-text text-lg font-medium block 3xl:ml-5 mb-[12px]">{firstUnderdogPost.featureName}</span>
                    </div>
                  </div>
                </div>
                <div className="inherit text-text group-hover:text-primary text-[18px] transform transition duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105 mt-4 3xl:group-hover:ml-5">{data.contentfulUnderdogPage.name}</div>
              </Link>
            </div>
          </div>
          <hr className=" mb-[20px] border-0 w-[90%] h-[1px] opacity-50  bg-gradient-to-r from-transparent via-border-color to-transparent" />
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
