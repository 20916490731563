/* eslint-disable no-dupe-keys */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */

import React, { useRef } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulTestimonial {
        heading
        content {
          name
          position
          content {
            content
          }
        }
      }
    }
  `);

  const settings = {
    dots: true,
    speed: 500,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slider = useRef(null);

  const commentListData = data.contentfulTestimonial.content;

  return (
    <section className="snap-center bg-background">
      <div className="lg::py-[40px] pt-[30px] pb-[20px]">
        <div className="bg-[#22252F]">
          <div className="lg:py-[40px] py-[40px] items-center block md:block lg:flex flex-col md:flex-row shadow-sm overflow-hidden">
            <div className="relative w-full py-2 md:pt-24 lg:pt-0 lg:py-0 md:pb-0 lg:w-[40%] md:w-full flex flex-col item-center justify-center">
              <div className="absolute top-0 left-0 z-10 grid-indigo w-16 h-16 md:w-40 md:h-40 md:ml-20 md:mt-24" />
              <div className="md:w-[40%] w-full relative md:text-headerH1Md text-headingH1 lg:text-headerH1 py-2 px-6 md:py-6 md:px-1 md:w-68 md:mx-auto text-white font-normal leading-tight text-headerH2 text-center tracking-tight mb-0 z-20">
                {data.contentfulTestimonial.heading}
              </div>
            </div>
            <div className="lg:w-[60%] md:w-full">
              <div className="flex flex-col h-full relative">
                <div className="absolute top-0 left-0 mt-3 ml-4 md:mt-[-0.8rem] md:ml-12">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-primary opacity-25 fill-current w-12 h-12 md:w-16 md:h-16" viewBox="0 0 24 24"><path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L9.758 4.03c0 0-.218.052-.597.144C8.97 4.222 8.737 4.278 8.472 4.345c-.271.05-.56.187-.882.312C7.272 4.799 6.904 4.895 6.562 5.123c-.344.218-.741.4-1.091.692C5.132 6.116 4.723 6.377 4.421 6.76c-.33.358-.656.734-.909 1.162C3.219 8.33 3.02 8.778 2.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C2.535 17.474 4.338 19 6.5 19c2.485 0 4.5-2.015 4.5-4.5S8.985 10 6.5 10zM17.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L20.758 4.03c0 0-.218.052-.597.144-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162C14.219 8.33 14.02 8.778 13.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C13.535 17.474 15.338 19 17.5 19c2.485 0 4.5-2.015 4.5-4.5S19.985 10 17.5 10z" /></svg>
                </div>
                <Slider ref={slider} {...settings} className="testimonial-wrapper h-full relative z-10">
                  {commentListData.map(({ content, name, position }, index) => (
                    <div key={index}>
                      <p className="text-text text-center font-normal italic px-6 py-6 md:px-16 md:py-2 text-xl md:text-2xl">
                        {content.content}
                      </p>
                      <div className="text-center" x-show="testimonialActive == 1">
                        <h2 className="text-sm md:text-base font-bold text-white leading-tight">{name}</h2>
                        <small className="text-text text-xs md:text-sm truncate">{position}</small>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
