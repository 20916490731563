/* eslint-disable quotes */
import React from "react";

import Seo from "../components/Seo";
import Layout from "../components/layout";
import HeroSection from "../components/HeroSection";
import PopularSection from "../components/PopularSection";
import PartnersSection from "../components/PartnerSection";
import HighlightSection from "../components/HighlightSection";
import ComingSoonSection from "../components/ComingSoonSection";
import TestimonialSection from "../components/TestimonialSection";

const IndexPage = () => (
  <Layout>
    <div className="overflow-hidden bg-background">
      <Seo title="Home" />
      <HeroSection />
      <HighlightSection />
      <PopularSection />
      <ComingSoonSection />
      <PartnersSection />
      <TestimonialSection />
    </div>
  </Layout>
);

export default IndexPage;
