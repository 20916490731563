/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

import DashIcon from '../icons/Dash';
import ArrowIcon from '../icons/Arrow';

const ComingsoonSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulComingSoon {
        heading
        duration
        subHeading
        buttonName
        buttonSlug
        releaseDate
        description {
          description
        }
        image {
          gatsbyImageData(placeholder: BLURRED)
          filename
          url
        }
      }
    }
  `);

  const content = data.contentfulComingSoon;

  return (
    <section className="bg-background">
      <div className="lg:py-[40px] py-[20px]">
        <div className="md:px-[2rem] px-[1.5rem] lg:px-[2.5rem]">
          <div className="grid grid-cols-1 gap-16 md:gap-12 md:grid-cols-1 2xl:grid-cols-2 lg:grid-cols-2 3xl:gap-12 items-center">
            <div className="group duration-200 delay-75 hover:z-30 z-20 order-last md:order-last 2xl:order-none lg:order-none cursor-auto">
              <div className="relative z-30 transition transform duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105  w-full col-span-2 inline-flex flex-col md:ml-21% 2xl:ml-0 lg:ml-0">
                <div className="after:group-hover:border-primary after:group-hover:bottom-[-3px] after:h-0 after:w-full after:absolute after:rounder-full after:group-hover:border-b-[0.25rem] after:left-0 after:z-10">
                  <GatsbyImage
                    className="md:max-w-none w-full"
                    image={content.image.gatsbyImageData}
                    alt={content.image.filename}
                  />
                </div>
              </div>
            </div>
            <div className="w-full max-w-full md:pr-15px">
              <div className="block items-center">
                <div className="mb-[8px] text-primary block text-sm tracking-1 font-medium w-full">{content.heading}</div>
              </div>
              <div className="mb-[20px] block">
                <h1 className="text-heading relative font-normal md:text-headerH1Md text-headerH1 lg:text-headerH1 mb-[2px]">
                  {content.subHeading}
                </h1>
                <DashIcon />
              </div>
              <p className="md:mt-[20px] mt-4 text-text pr-0 text-16md md:text-2md font-normal">
                {content.description.description}
              </p>
              <div className="md:justify-start mt-[15px] flex sm:justify-center lg:justify-start">
                <div>
                  <Link
                    to={content.buttonSlug}
                    className="flex items-center justify-center px-[1.5rem] min-w-[10.625rem] py-[1rem] border border-transparent rounded-md shadow-sm text-lg font-medium text-background bg-secondary hover:bg-heading"
                  >
                    <span>{content.buttonName}</span>
                    <ArrowIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-[40px] border-0 w-[90%] h-[1px] opacity-50  bg-gradient-to-r from-transparent via-border-color to-transparent" />
        </div>
      </div>
    </section>
  );
};

export default ComingsoonSection;
