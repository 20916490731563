/* eslint-disable quotes */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const PartnerSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulPartners {
        heading
        logos {
          name
          url
          logoImage {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const partner = data.contentfulPartners.logos;

  return (
    <section className="snap-center bg-background">
      <div className="lg:py-[40px] py-[20px]">
        <div className="px-0 2xl:px-[6.25rem] lg:px-3 md:px-[3.125rem]">
          <div className="">
            <div className="pb-[40px]">
              <div className="md:text-center afetr:content after:h-[0.6px] after:mt-[4px] after:bg-primary after:w-[80px] after:absolute after:left-0 after:right-0 after:mx-auto">
                <h1 className="text-heading font-normal md:text-headerH1Md text-headerH1 lg:text-headerH1 relative  text-center mb-[2px]">
                  {data.contentfulPartners.heading}
                </h1>
              </div>
            </div>
            <div className="container">
              <div className="flex flex-wrap -mx-">
                <div className="w-full px-4">
                  <div className="flex flex-wrap justify-center items-center">
                    {partner.map(({ name, logoImage, url }, index) => (
                      <a
                        key={index}
                        href={url}
                        className="
                        py-5
                        flex
                        lg:mx-6
                        ml-6
                        mr-0
                        w-[20%]
                        items-center
                        justify-center
                        "
                      >
                        <GatsbyImage
                          image={logoImage.gatsbyImageData}
                          alt={name}
                          className="w-full h-full"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerSection;
